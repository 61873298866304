<template>
  <v-card>
    <v-card-title>
      Stores
      <v-icon class="ml-2" :color="siteColor" @click="addStore">mdi-store-plus-outline</v-icon>
      <div class="flex-grow-1"></div>
      <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table
      v-if="stores.length"
      :headers="headers"
      :items="stores"
      :items-per-page="30"
      item-key="id"
      class="elevation-1"
      :search="search"
      sort-by="approved"
    >
      <template v-slot:body="{items}">
        <tbody>
          <template v-for="item in items">
            <tr :key="item.id">
              <td>{{ item.name }}</td>
              <td style="display: flex; justify-content: end;"><v-icon color="red" @click="confirmDelete(item.id)">mdi-store-remove-outline</v-icon></td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "stores",
  data() {
    return {
      search: "",
      headers: [
        { text: "Name", value: "name" },
        { text: "", value: "", sortable: false }
      ]
    };
  },
  async created() {
    await this.$store.dispatch("store/allStores");
    // await this.$store.dispatch("store/addStore", "Petro");
  },
  computed: {
    ...mapState("user", ["user", "users"]),
    ...mapState("store", ["stores"]),
    ...mapGetters("user", [
      "isSuper",
    ])
  },
  methods: {
    confirmDelete(id) {
      this.$swal({
      title: 'Remove Store',
      text: "Are you sure you want to remove this store?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4baf4f',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      'cancelButtonText': 'No',
      'iconColor': '#d33'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.deleteStore(id)
        }
      });
    },
    async deleteStore(id) {
      await this.$store.dispatch("store/removeStore", id);
    },
    async addStore() {
      this.$swal({
      title: 'Add Store',
      input: "text",
      inputLabel: "Store Name",
      showCancelButton: true,
      confirmButtonColor: '#4baf4f',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Add',
      'iconColor': '#d33',
      inputValue: "",
      inputValidator: (value) => {
        if (!value) {
          return "You need to enter a store name!";
        }
      }
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.$store.dispatch("store/addStore", result.value);
        }
      });
      
    },
  }
};
</script>

<style lang="scss" scoped>
tr {
  cursor: pointer;
}
table {
  td {
    .v-text-field {
      border-color: #ffffff1f;
      padding-top: 0px;
      margin-top: 0px;
      font-size: 14px;
    }
  }
}
</style>